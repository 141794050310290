const API_KEY = 'AIzaSyAkM4ipSTdWngRooeCBKnMgWvfcdUAWUQk'
const CALENDAR_ID = 'e8db3ghlgpn9eblgibb20ouh9s@group.calendar.google.com'

/**
 * List all events starting no earlier than today
 * @returns A list of the upcoming Google Calendar events
 */
export async function getUpcomingEvents(): Promise<[Event]> {
    const response = await fetch(`https://www.googleapis.com/calendar/v3/calendars/${CALENDAR_ID}/events?key=${API_KEY}&timeMin=${getDateString()}&singleEvents=true&orderBy=startTime`)
    const data: CalendarResponse = await response.json()
    if (data.items === undefined) {
        throw Error("event list is undefined")
    } else {
        return data.items
    }
}

getUpcomingEvents()

interface CalendarResponse {
    items?: [Event]
}

export interface Event {
    summary?: string
    description?: string
    location?: string
    start?: {
        // will provide either a date (if no time) or a datetime
        date?: string
        dateTime?: string
        timeZone?: string
    }
    end?: {
        date?: string
        dateTime?: string
        timeZone?: string
    }
    htmlLink?: string
}

/**
 * Returns an ISO string of the current date at midnight in the current timezone
 */
function getDateString(): String {
    let date = new Date() // set to current date and time
    date.setHours(0, 0, 0, 0)
    return date.toISOString()
}

/**
 * Given a Google Calendar event, nicely format its date and time
 * @param event a Google calendar event
 * @returns a string like "Monday October 5, 2-3 PM"
 */
export function formatEventDateTime(event: Event): string | null {
    const date = formatEventDate(event)
    const time = formatEventTime(event)

    if (!date) {
        return null
    } else {
        if (time) {
            return `${date}, ${time}`
        } else {
            return date
        }
    }

    return null
} 

/**
 * Given an event, nicely format its date
 * @param event Google calendar event
 * @returns a string like "Monday October 5"
 */
function formatEventDate(event: Event): string | null {
    if (!event.start || !event.start.date && !event.start.dateTime) {
        return null
    }

    // from the if check above, we know we have a dateTime or a date,
    // but typescript doesn't know this, so we suppress the error
    // @ts-ignore
    const start = new Date(event.start.date || event.start.dateTime)

    const day_of_week = getDayOfWeek(start)
    const month = getMonth(start)
    const date = start.getDate()

    return `${day_of_week} ${month} ${date}`
}

/**
 * Given an event, nicely format its time
 */
function formatEventTime(event: Event): string | null {
    if (!event.start || !event.start.dateTime) {
        return null
    }

    const start = new Date(event.start.dateTime)

    // end may be null
    const end = event.end?.dateTime && event.end.timeZone && new Date(event.end.dateTime)

    return format_time_range(start, end)
}

function getDayOfWeek(datetime: Date) {
    return [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
    ][datetime.getDay()]
}

function getMonth(datetime: Date) {
    return [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ][datetime.getMonth()]
}

function get_AM_PM(datetime: Date) {
    if (datetime.getHours() >= 12)
        return "PM"
    else
        return "AM"
}

/**
 * 
 * @param start 
 * @param end 
 */
function format_time_range(start: Date | undefined, end: Date | undefined | ""): string | null {
    if (!start) {
        return null
    }

    const start_time = format_12hr_time(start)
    const start_am_pm = get_AM_PM(start)

    if (!end) {
        // e.g. "5 PM"
        return `${start_time} ${start_am_pm}`
    } else {
        const end_time = format_12hr_time(end)
        const end_am_pm = get_AM_PM(end)

        if (start_am_pm == end_am_pm) {
            //e.g. "5-7 PM"
            return `${start_time}-${end_time} ${start_am_pm}`
        } else {
            // e.g. "11 AM - 1 PM"
            return `${start_time}${start_am_pm} - ${end_time}${end_am_pm}`
        }
    }
}

/**
 * Given a datetime, format the time in 12-hour format w/o the AM/PM part
 * e.g. "3:04" (this could be AM or PM)
 * If the minute is 0, then it is not included, e.g. just "5" for 5 PM
 * @param datetime the datetime to format
 */
function format_12hr_time(datetime: Date): string {
    let hour = datetime.getHours()
    if (hour > 12) {
        hour -= 12
    }

    const minute = datetime.getMinutes()

    if (minute == 0) {
        return hour.toString()
    } else {
        const minute_string = minute.toString().padStart(2, '0')
        return `${hour}:${minute_string}`
    }
}


getDateString() //=
getUpcomingEvents()