import { getUpcomingEvents, Event, formatEventDateTime } from "./calendar_client";

/**
 * Fetch the upcoming events and add them to the DOM
 */
async function loadEvents() {
    const eventContainer = document.getElementById("event-list")

    try {
        const events = await getUpcomingEvents()

        if (events.length > 0) {
            const ul = document.createElement("ul")
            ul.replaceChildren(
                ...events.map(event => eventToDomNode(event))
            )

            eventContainer?.replaceWith(ul)
        } else {
            const notice = nodeWithText("p","Events for this week have not been added yet. Check back again in a few days.") as HTMLParagraphElement
            notice.className = "error"
            eventContainer?.replaceChildren(
                ...[notice]
            )
        }
    } catch (err) {
        console.error(err)

        const p = document.createElement("p")
        p.className = "error"
        p.innerText = "Error: failed to load events. "

        const retryLink = nodeWithText("a", "(Click here to retry)") as HTMLAnchorElement
        retryLink.onclick = loadEvents
        p.appendChild(retryLink)

        eventContainer?.replaceChildren(...[p as Node])
    }
}

loadEvents()


/**
 * Given a calendar event, generate a DOM node (list item) displaying its info
 * @param event the Google Calendar event
 * @returns a li DOM node containing the event's info
 */
function eventToDomNode(event: Event): Node {
    const li = document.createElement("li")



    const header = document.createElement("b")

    const calendarLink = externalLinkWithTextAndHref(formatEventDateTime(event) || "Date pending", event.htmlLink || "")
    calendarLink.className = "calendar-link"
    header.appendChild(calendarLink)

    header.appendChild(
        document.createTextNode(": ")
    )

    header.appendChild(
        nodeWithText("i", event.summary || "Unnamed event")
    )
    li.appendChild(header)


    if (event.location) {
        const locationInfo = document.createElement("p")
        locationInfo.appendChild(
            nodeWithText("b", "Location: ")
        )
        const locationLink = externalLinkWithTextAndHref(event.location, `https://www.google.com/maps?q=${event.location}`)
        locationLink.className = "dotted"
        locationInfo.appendChild(locationLink)

        li.appendChild(locationInfo)
    }

    if (event.description) {
        const details = document.createElement("details")
        details.appendChild(
            document.createElement("summary")
        )

        const p = document.createElement("p")
        p.innerHTML = event.description
        details.appendChild(p)

        li.appendChild(details)
    }

    return li as Node
}

/**
 * Create a DOM node with some inner text
 * @param type 'p', 'li', 'b', etc
 * @param text the text to be inside the node
 * @returns a DOM node
 */
function nodeWithText(type: string, text: string) {
    const node = document.createElement(type)
    node.innerText = text
    return node as Node
}

/**
 * Creates a <a> node with a given innerText body and href
 * @param text what the innerText of the link should be
 * @param href what the href (destination) of the link should be
 */
function externalLinkWithTextAndHref(text: string, href: string) {
    let a = nodeWithText("a", text) as HTMLAnchorElement
    a.href = href
    a.target = "_blank"
    a.rel = "noopener noreferrer"
    return a
}